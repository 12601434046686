<style scoped>
    .card.question .card-title {
        font-weight: 600;
    }

    .card.question .card-content {
        padding: .25em .5em;
    }

    .card.question .card-action {
        height: 200px;
        overflow-y: auto;
        background: linear-gradient(to bottom, rgba(252, 238, 238,0.7) 0%,rgba(252, 238, 238,0.7) 100%), url(@/assets/img/pic/question.png) calc(100% - 1em) calc(100% - 1em) / 6em 6em no-repeat;
        padding: .1em 1em;
    }

    .card.question .card-action > div {
        margin: .5em 0;
    }

    .card.question .card-action label {
        color: #3c3c3c;
    }

    .card.question .card-action [type="radio"]:not(:checked)+label, [type="radio"]:checked+label {
        font-size: 1.5em;
        height: auto;
        width: 100%;
    }

    .card.question .card-action [type="radio"]:checked+label:after {
        border: 2px solid #ee6e73;
        background-color: #ee6e73;
    }

    .card.question .card-action [type="radio"]:checked+label {
        font-weight: 600;
        background-color: rgba(255, 207, 214, 0.8);
        border-radius: 1em;
    }
</style>
<template>
    <div class="card question">
        <div class="card-content white-text red lighten-2">
            <span class="card-title">{{question.text}}</span>
        </div>
        <div class="card-action">
            <div v-for="(o, index) in question.options">
                <input type="radio" @change="$emit('input', $event.target.value)" :name="'answers[' + question.id + ']'" :id="'q' + question.id + 'o' + o.id" :value="o.id" :checked="index == 0"/>
                <label class="waves-effect waves-red" :for="'q' + question.id + 'o' + o.id">{{o.text}}</label>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        props:{
            val:{},
            question:{}
        },
        data(){
            return{
            }
        },
    }
</script>
