<template>
    <div class="container-fluid">
        <navigation @show-menu="onShowMenu"></navigation>
        <main :class="{ 'scale-down': showMenu }">
            <slot></slot>
            <div class="fixed-action-btn click-to-toggle">
                <a class="btn-floating btn-large red lighten-2">
                    <i class="material-icons">more_horiz</i>
                </a>
                <ul>
                    <li v-if="$route.meta.showCompare"><a class="btn-floating red lighten-2 show-on-large" @click="toggleCompare"><i class="material-icons">compare</i></a></li>
                    <li><a id="search-panel-button" href="#" data-activates="search-panel-slide-out" class="btn-floating red lighten-2 show-on-large"><i class="material-icons">search</i></a><filters></filters></li>
                    <li><a class="btn-floating red lighten-2 show-on-large" href="https://www.youtube.com/user/SAKURATW" target="blank">YT</a></li>
                    <li><a class="btn-floating red lighten-2 show-on-large" href="https://www.facebook.com/sakura.tw" target="blank">FB</a></li>
                    <li><a class="btn-floating red lighten-2 show-on-large" href="https://s.aiii.ai/g/5e2845" target="blank">LINE</a></li>
                    <li><a class="btn-floating red lighten-2 show-on-large" @click="back"><i class="material-icons">arrow_back</i></a></li>
                </ul>
            </div>
        </main>
        <login-modal></login-modal>
                        <!-- Modal Structure -->
                        <vue-modal id="video-modal" style="" class="modal-fixed-footer">
                                <div class="row">
                                    <fancybox-button v-if="product.video_url != ''" class="col" style="margin:7px 0;" :href="`${product.video_url}?enablejsapi=1&autoplay=1&loop=1`"
                                                    iframe
                                                    loop
                                                    text="影片介紹"
                                                    color="grey"
                                                    width="100%"
                                                    height="85%"
                                                    fa-icon="film"
                                                    :auto-size="false"
                                                    v-ga="$ga.commands.trackProductShowClick.bind(this, product.model, '影片介紹')"
                                    >
                                    </fancybox-button>
                                    <fancybox-button class="col" style="margin:7px 0;" v-for="v in product.youtube_videos" :key="v.id" :href="`${v.url}?enablejsapi=1&autoplay=1&loop=1`"
                                                    iframe
                                                    loop
                                                    :text="v.title"
                                                    color="grey"
                                                    width="100%"
                                                    height="85%"
                                                    fa-icon="film"
                                                    :auto-size="false"
                                                    v-ga="$ga.commands.trackProductShowClick.bind(this, product.model, '影片介紹')"
                                    >
                                    </fancybox-button>
                                </div>
                            <div slot="footer">
                                <a href="#!" class="modal-action modal-close waves-effect waves-green btn-flat">關閉</a>
                            </div>
                        </vue-modal>
    </div>
</template>

<style scoped>
    .fixed-action-btn {
        bottom: 11em;
    }
</style>

<script>
    import { mapMutations } from 'vuex'
    export default{
        data(){
            return{
                showMenu: false,
                product:{},
            }
        },
        methods:{
            onShowMenu(showMenu){
                this.showMenu = showMenu
            },
            ...mapMutations('compare', [
                'toggleCompare'
            ]),
            back(){
                window.history.back()
            },
        },
        mounted(){
            this.$bus.$on('product',(product) => {
                this.product = product
            })
        }
    }
</script>