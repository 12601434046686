<style>
    .tp-thumbs {
        padding-left: 25px !important;
    }

    .tp-bannertimer {
        display:none;
    }

    .tparrows:before {
        color: gray;
    }

    .gyges .tp-thumb.selected .tp-thumb-img-wrap {
         background: white;
    }

    .gyges .tp-thumb-img-wrap {
        background-color: transparent;
    }

    .fancybox-prev span, .fancybox-next span {
        background-image: none;
    }

    /*.fancybox-nav span {
        visibility: visible;
    } */

    .fancybox-prev span:before {
        font-family: "revicons";
        font-size: 2em;
        content: '\e824';
        color: gray;
    }

    .fancybox-next span:before {
        font-family: "revicons";
        font-size: 2em;
        content: '\e825';
        color: gray;
    }

    img {
        max-height: 100%;
    }
</style>

<template>
    <div class="rev_slider_wrapper">
        <div v-show="show" :id="sliderId" class="rev_slider">
            <ul>
                <li v-for="image in images" data-transition="none" :data-thumb="image">
                    <a :href="image" class="slider-fancybox">
                        <img :src="image" class="rev-slidebg" style="background-color: #fff;">
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default{
        props:{
            sliderId:{},
            images:{}
        },
        data(){
           return {
              show: true
           }
        },
        methods:{
            initFancybox(){
                $(this.$el).find('.slider-fancybox').attr('rel', 'fancybox-thumb').fancybox({
                    tpl: {
                        next: '<a title="Next" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
                        prev: '<a title="Previous" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
                    },
                });
            },
            initSlider(){
                window.revapi && window.revapi.revkill()
                window.revapi = $(`#${this.sliderId}`).revolution({
                    sliderLayout: 'auto',
                    autoHeight: 'on',
                    gridwidth: 1000,
                    gridheight: 500,
                    navigation: {
                        arrows: {
                            enable: true,
                            style: 'uranus',
                            hide_onleave: false,
                        },
                        thumbnails: {
                            enable: true,
                            position:'outer-bottom',
                            hide_onleave: false,
                            style: 'gyges',
                            width: 100,
                            height: 100,
                            min_width: 100,
                            h_align: 'center',
                            span: true,
                            wrapper_padding: 50,
                            wrapper_color: 'transparent',
                            space: 5,
                            tmp: '<span class="tp-thumb-img-wrap"><span class="tp-thumb-image"></span></span>'
                        }
                    },
                })
                window.revapi.one('revolution.slide.onloaded', () => {
                     this.show = false
                     setTimeout(_ => {
                        this.show = true
                     }, 500)
                });
            },
            init(){
                this.initSlider()
                this.initFancybox()
            },
        },
        watch:{
            images(){
                this.init()
            },
            $route(){
                this.init()
            }
        },
        mounted(){
            this.$nextTick().
            then( _ => {
                this.init()
            })
        },
    }
</script>
