/**
 * Created by dexterliu on 2017/1/4.
 */
const compare = {
    state: {
        products: [],
        showCompare: true
    },
    getters: {
        products: state => {
            return state.products
        },
        showCompare(state){
            return state.showCompare
        }
    },
    mutations: {
        toggleCompare(state){
            return state.showCompare = !state.showCompare
        },
        add (state, product) {
            state.products.push(product)
            if(state.products.length > 2) {
                state.products.pop()
                alert('您已勾選兩項產品')
            }
        },
        remove(state, product) {
            const index = state.products.findIndex(p => p.id == product.id)
            if(index === -1) return
            state.products.splice(index, 1)
        },
        clear(state){
            state.products = []
        }
    },
    actions: {
        add (context, product) {
            context.commit('add', product)
        },
        remove (context, product) {
            context.commit('remove', product)
        },
        clear (context) {
            context.commit('clear')
        }
    }
}

export default compare