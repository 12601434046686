<template>
    <nav>
        <div class="nav-wrapper">
            <a href="/" class="brand-logo">
                <img src="@/assets/img/logo.png">
            </a>

            <ul class="side-nav hide-on-large-only" id="mobile-menu">
                <li v-if="channel" v-for="m in menuItems" @click="m.onClick">
                    <a v-if="m.external" :href="m.path" target="_blank" :class="m.a_class" v-text="m.name"></a>
                    <a v-else-if="m.path == ''" :class="m.a_class" v-text="m.name"></a>
                    <router-link v-else :to="m.path" v-text="m.name"></router-link>
                </li>
            </ul>

            <ul class="right">
                <li>
                    <a href="#" data-activates="mobile-menu" class="button-mobile-menu hide-on-large-only">
                        <i class="material-icons">menu</i>
                    </a>
                    <a href="#" class="cd-nav-trigger hide-on-med-and-down" @click.prevent.stop="showMenu = true">
                        <i class="material-icons">apps</i>
                    </a>
                </li>
            </ul>
            <div class='marquee hide-on-med-and-down' data-duration='10000' v-text="marquee"></div>
        </div>
        <nav class="cd-nav-container" :class="{ 'is-visible': showMenu }" id="cd-nav">
            <header>
                <h3>資訊導覽 <span v-if="Account != ''">您已登入為{{Account}}</span></h3>
                <a href="#" class="cd-close-nav btn-floating waves-effect waves-light blue-grey darken-4" @click.prevent.stop="showMenu = false">Close</a>
            </header>
            <ul class="cd-nav">
                <li v-if="channel" v-for="m in menuItems" @click="m.onClick">
                    <a v-if="m.external" :href="m.path" target="_blank" :class="m.a_class" v-html="m.content"></a>
                    <a v-else-if="m.path == ''" :class="m.a_class" v-html="m.content"></a>
                    <router-link v-else :to="m.path" v-html="m.content" ></router-link>
                </li>
            </ul>
        </nav>
        <div class="cd-overlay" :class="{ 'is-visible': showMenu }" @click="showMenu = false"></div>
    </nav>
</template>

<script>
    export default{
        name:'Navigation',
        data(){
            return{
                showMenu:false,
            }
        },
        computed:{
            marquee(){
                return (this.channel) ? this.channel.marquee : 'Loading...'
            },
            menuItems(){
                let self = this
                return [
                    {
                        name:'首頁',
                        path:`/${this.token}${this.source}`,
                        a_class:'',
                        content:`<img src="/img/fa-home.png"/> <em>首頁</em>`,
                        onClick(){
                            self.showMenu = false
                        }
                    },
                    {
                        name:'產品介紹',
                        path:`/${this.token}/product-classes/parent/0${this.source}`,
                        a_class:'',
                        content:`<img src="/img/fa-cubes.png"/> <em>產品介紹</em>`,
                        onClick(){
                            self.showMenu = false
                        }
                    },
                    {
                        name:'影片介紹',
                        path:`/${this.token}/video-on-demand${this.source}`,
                        a_class:'',
                        content:`<img src="/img/fa-youtube-play.png"/> <em>影片介紹</em>`,
                        onClick(){
                            self.showMenu = false
                        }
                    },
                    {
                        name:'DM介紹',
                        path:`/${this.token}/dm${this.source}`,
                        a_class:'',
                        content:`<img src="/img/dm.png"/> <em>DM介紹</em>`,
                        onClick(){
                            self.showMenu = false
                        }
                    },
                    {
                        name:'SAKURA 官網',
                        path:'https://www.sakura.com.tw',
                        a_class:'',
                        external: true,
                        content:`<img src="/img/logo-o.png" width="100"> <em>SAKURA 官網</em>`,
                        onClick(){
                            self.showMenu = false
                        }
                    },
                    {
                        name:'夥伴雲',
                        path: '',
                        a_class:'',
                        content:`<img src="/img/logo-cloud.png" width="100"> <em>夥伴雲</em>`,
                        onClick(){
                            self.showMenu = false,
                            $('#login-modal').modal('open')
                        }
                    }
                ]
            },
        },
        watch:{
            showMenu(){
                this.$emit('show-menu', this.showMenu)
            },
            channel(){
                this.$nextTick().then((_ => {
                    $('.marquee').marquee('destroy');
                    $('.marquee').marquee();
                }))
            }
        },
        mounted(){
            setTimeout(function(){
                $(".button-mobile-menu").sideNav({
                   closeOnClick: true
               });
               $(window).on('resize', _ => {
                    $('.marquee').marquee('destroy');
                    $('.marquee').marquee();
               })
            }, 1000)
            this.$nextTick().then((_ => {
                $('.marquee').marquee('destroy');
                $('.marquee').marquee();
            }))
        }
    }
</script>

<style scoped>
    .brand-logo, .brand-logo img {
        height: 2em;
    }

    .marquee {
      overflow: hidden;
      font-size: 2em;
        position:absolute;
        right:2em;
        left:7.5em;
    }
</style>