<style scoped>
    .price-memo {
        font-size: 1.3em;
    }
</style>

<template>
    <div class="card" style="background: rgb(247, 245, 240);">
        <div class="card-content black-text">
            <div v-if="canShare">
                <i class="fas fa-share fa-2xl" @click.prevent="showShare"></i>
            </div>
            <div v-else class="right">
                <a href="#" @click="share('http://www.facebook.com/share.php?u=')"><img src="@/assets/img/fb_icon.png" title="Facebook" alt="Facebook"/></a>
                <a href="#" @click="share('https://lineit.line.me/share/ui?url=')"><img src="@/assets/img/line_icon.png" title="Line" alt="Line"/></a>
                <a v-if="canCopy" href="#" @click="copyToClipboard"><img src="@/assets/img/link_icon.png" title="Link" alt="Link"/></a>
                <a v-else class="share-link" :href="shareLink" @click.prevent="oldWayCopyToClipboard"><img src="@/assets/img/link_icon.png" :title="shareLink" :alt="shareLink"/></a>
            </div>

            <div class="card-title hide-on-large-only">
                <h4 v-text="product.model"></h4>
            </div>
            <div class="card-title hide-on-large-only">
                <h4 v-text="product.name"></h4>
            </div>

            <div class="card-title hide-on-med-and-down">
                <h3 v-text="product.model"></h3>
            </div>
            <div class="card-title hide-on-med-and-down">
                <h3 v-text="product.name"></h3>
            </div>
            <div class="row" v-for="sp in product.size_prices">
                <div class="col s12">
                    <h5 class="red-text" style="display: inline-block">建議售價：<strong>{{sp.price | currency}}</strong></h5>
                    <h5 style="display: inline-block" v-if="sp.size != ''">&nbsp;/ {{sp.size}}</h5>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <span class="channel-limit-text" v-text="product.channel_limit_text"></span>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <span class="price-memo" v-text="product.price_memo"></span>
                </div>
            </div>

            <div class="row" style="margin-bottom: 0;" v-for="features in chunk(product.features, 2)">
                <div v-for="f in features" class="col s12 m12 l6">
                    <div class="row valign-wrapper" style="margin-bottom: 1em;">
                        <div class="col s2 m1 l2" style="padding: 0;">
                            <img class="responsive-img feature-img" :src="f.images_url[0]">
                        </div>
                        <div class="col s10 m11 l10">
                            <span class="block-text" v-text="f.name"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    export default{
        props:{
            product:{}
        },
        data(){
            return{
                canShare: window.navigator.share,
                canCopy: window.navigator.permissions,
                shareLink: window.location.href,
            }
        },
        methods:{
            chunk(array, size){
                return _.chunk(array, size)
            },
            showShare(){
                navigator.share({
                    url:location.href,
                    text:`${this.product.model} ${this.product.name}`,
                })
            },
            share(url){
                let shareURL = `${url}${encodeURIComponent(location.href)}` 
                if (!window.open(shareURL)) {
                    location.replace(shareURL)
                }
            },
            async copyToClipboard() {
                try {
                    await navigator.clipboard.writeText(location.href)   
                    alert('已複製到剪貼簿')
                } catch (error) {
                    this.oldWayCopyToClipboard()
                }
            },
            oldWayCopyToClipboard(){
                let link = document.querySelector('.share-link')
                let range = document.createRange()
                range.selectNode(link)
                window.getSelection().addRange(range)
                try {
                    document.execCommand('copy')
                    alert('已複製到剪貼簿')
                } catch(err) {
                    console.log('Oops, unable to copy');
                }
                window.getSelection().removeAllRanges();
            }
        }
    }
</script>
