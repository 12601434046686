<template>
    <form id="search-panel-slide-out" class="side-nav" @submit.prevent="onSearch">
        <div class="row">
            <div class="col s12">
                <div class="section">
                    <h5>關鍵字</h5>
                    <p>
                        <input id="input-search" type="text" v-model="keyword" placeholder="型號或名稱">
                    </p>
                    <button class="btn waves-effect waves-light red lighten-2" type="submit" name="action">搜尋
                        <i class="material-icons right">search</i>
                    </button>
                </div>
            </div>

            <div class="col s12">
                <template v-if="classes.length">
                    <div class="section">
                        <h5>系列</h5>
                        <p v-for="c in classes">
                            <input type="checkbox" :id="'class' + c.id" :value="c.id" v-model="checkedClasses"/>
                            <label :for="'class' + c.id" v-text="c.name"></label>
                        </p>
                        <p>
                            <input type="checkbox" id="all" v-model="checkAll"/>
                            <label for="all">全部</label>
                        </p>
                    </div>
                </template>
            </div>
            <div class="col s12">
                <div class="section">
                    <h5>價格</h5>
                    <p v-for="p in priceRanges">
                        <input name="priceRanges" type="radio" :id="'price-ranges' + p.id" :value="p.value" v-model="priceRange"/>
                        <label :for="'price-ranges' + p.id" v-text="p.name"></label>
                    </p>
                </div>
                <button class="btn waves-effect waves-light red lighten-2" type="submit" name="action">搜尋
                    <i class="material-icons right">search</i>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default{
        data(){
            return {
                classes:[],
                isLoading: false,
                checkedClasses:[],
                keyword:'',
                priceRange:'',
                priceRanges:[
                    {
                        id:1,
                        name:'5,000元以下',
                        value:{ min_price: 0, max_price: 5000 }
                    },
                    {
                        id:2,
                        name:'5,001~10,000元',
                        value:{ min_price: 5001, max_price: 10000 }
                    },
                    {
                        id:3,
                        name:'10,001~15,000元',
                        value:{ min_price: 10001, max_price: 15000 }
                    },
                    {
                        id:4,
                        name:'15,001~20,000元',
                        value:{ min_price: 15001, max_price: 20000 }
                    },
                    {
                        id:5,
                        name:'20,001元以上',
                        value:{ min_price: 20001, max_price: 9999999 }
                    },
                    {
                        id:6,
                        name:'不限',
                        value:{ min_price: 0, max_price: 9999999 }
                    }
                ],
                checkAll:false,
            }
        },
        methods:{
            onSearch(){
                router.push({
                    path: `/${this.token}/search-result?${this.searchQuery}`,
                })
            },
            async fetchClass(id){
                if(!this.channel || !id) {
                    setTimeout(_ => {
                        this.fetchClass(id)
                    }, 1000)
                    return
                }
                this.isLoading = true

                let extraProducs = [].map(i => i.ProdNo);
                if(this.uofid) {
                    try {
                        let res = await this.$http.get(`${window.API_URL}/api/uof_products/${this.uofid}`);
                        extraProducs = (await res.json()).map(i => i.ProdNo);
                    } catch (error) {
                        console.log(error);
                    }
                }
                let res = await this.$http.get(window.API_URL + `/api/product_class?brand_id=1&parent_id=${id}&channel_id=${this.channel.id}&model=${extraProducs.join(",")}`)
                this.classes = res.data
                this.isLoading = false
                if(this.token == '6' && this.$route.params.parent_id == 0) {
                    let productSets = {
                        id:'product-sets',
                        childen_count: 0,
                        parent_id: 0,
                        class_images: [ "/img/product-sets/icon.png" ],
                    }
                    this.classes.push(productSets)
                }
            },
        },
        computed:{
            ...mapGetters('global', [
                'channels'
            ]),
            searchQuery(){
                let params = {
                    brand_id: 1,
                    token: this.$route.params.token
                }
                if(this.keyword != ''){
                    params.keyword = this.keyword
                }

                if(this.priceRange != ''){
                    params.min_price = this.priceRange.min_price
                    params.max_price = this.priceRange.max_price
                }

                if(this.checkedClasses.length) {
                    params.classes = this.checkedClasses
                }

                return this.obj2query(params)
            },
        },
        watch:{
            checkAll(){
                this.checkedClasses = this.checkAll ? this.classes.map(function(i){
                    return i.id
                }) : [];
            },
            $route(){
                this.fetchClass(this.$route.params.parent_id ?? this.$route.params.product_class_id);
            },
            channel(){
                this.fetchClass(this.$route.params.parent_id ?? this.$route.params.product_class_id);
            },
        },
        created(){
            this.fetchClass(this.$route.params.parent_id ?? this.$route.params.product_class_id)
        },
        mounted(){
            this.$nextTick().then(_ => {
                $(`#search-panel-button`).sideNav();
            })
        },
    }
</script>

<style scoped>
    [type="checkbox"]:checked+label:before {
        border-right: 2px solid #e57373;
        border-bottom: 2px solid #e57373;
    }

    [type="radio"]:checked+label:after {
        border: 2px solid #e57373 !important;
        background-color: #e57373 !important;
    }
</style>