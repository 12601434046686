/**
 * Created by dexterliu on 2017/1/7.
 */
import { mapGetters } from 'vuex'
import Navigation from './Navigation.vue'
import Layout from './Layout.vue'
import Breadcrumb from './components/Breadcrumb.vue'
import Filters from './components/Filters.vue'
import ProductClass from './components/ProductClass.vue'
import Product from './components/Product.vue'
import ProductCompare from './components/ProductCompare.vue'
import HelpButton from './components/HelpButton.vue'
import VueModal from './components/VueModal.vue'
import MaterialSelect from './components/MaterialSelect.vue'
import LoginModal from './components/LoginModal.vue'
import Carousel from './components/Carousel.vue'
import RevolutionSlider from './components/RevolutionSlider.vue'
import FancyboxButton from './components/FancyboxButton.vue'
import ProductDetail from './components/ProductDetail.vue'
import Question from './components/Question.vue'

export default {
    components:{
        Question,
        ProductDetail,
        FancyboxButton,
        RevolutionSlider,
        Carousel,
        MaterialSelect,
        Navigation,
        Layout,
        Breadcrumb,
        Filters,
        ProductClass,
        Product,
        ProductCompare,
        HelpButton,
        VueModal,
        LoginModal
    },
    methods:{
        obj2query(obj, prefix) {
            let str = [], p;
            for(let p in obj) {
                if (obj.hasOwnProperty(p)) {
                    let k = prefix ? prefix + `[${p}]` : p, v = obj[p];
                    str.push((v !== null && typeof v === "object") ?
                        this.obj2query(v, k) :
                        encodeURIComponent(k) + "=" + encodeURIComponent(v));
                }
            }
            return str.join("&");
        },
    },
    computed:{
        ...mapGetters('global', [
            'channels',
            'isLogin',
            'Account',
            'Domain',
        ]),
        channel(){
            return this.channels.find(c => c.token == this.$route.params.token)
        },
        token(){
            return this.$route.params.token || 1
        },
        uofid(){
            if(this.$route.query.uofid) {
                return this.$route.query.uofid.replace('sakura\\', '')
            }
            return ''
        },
        source(){
            let obj = {}
            if(this.$route.query.source) {
                obj.source = this.$route.query.source
            }
            if(this.$route.query.uofid) {
                obj.uofid = this.$route.query.uofid
            }
            let query = '?' + this.obj2query(obj)
            return query
        },
    }
}