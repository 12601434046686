<style>
</style>

<template>
    <div>
        <a ref="fancybox" :href="href" class="waves-effect waves-light btn" :class="[ color, {'fancybox.iframe':iframe}, {'uof-file': isUofFile} ]">
            <i v-if="faIcon" class="fa" :class="[ 'fa-' + faIcon ]"></i> {{text}}
        </a>
        <div v-if="containerId" :id="containerId" style="display: none;">
            <slot></slot>
        </div>
        <slot v-else></slot>
    </div>
</template>

<script>
    export default{
        props:{
            containerId:{},
            iframe:{
                type: Boolean,
                default: false
            },
            loop:{
                type: Boolean,
                default: false
            },
            isUofFile:{
                type: Boolean,
                default: false
            },
            width:{},
            height:{},
            autoSize:{
                type: Boolean,
                default: true
            },
            text:{},
            faIcon:{},
            color:{},
            href:{}
        },
        data(){
            return{
            }
        },
        mounted(){
            let options = {}
            if(this.width) {
                options.width = this.width
            }
            if(this.height) {
                options.height = this.height
            }

            if(this.loop) {
                options.beforeShow = () => {
                    let id = $.fancybox.inner.find('iframe').attr('id')
                    let player = new YT.Player(id, {
                        events:{
                            onReady:function(e) {
                                e.target.playVideo()
                            },
                            onStateChange:function(e){
                                if(e.data == 0) {
                                    e.target.playVideo()
                                }
                            }
                        }
                    })
                }
            }

            options.autoSize = this.autoSize
            $(this.$refs.fancybox).fancybox(options)
        }
    }
</script>
