<style scoped>
.dm-box > a i {
    display: inline-block;
    margin-bottom: .5em;
    width: 3em;
    height: 3em;
    background: url(@/assets/img/pic/raising-hand-for-question.png) no-repeat center;
    background-size: contain;
}

.dm-box .chip {
    padding: .5em .25em;
    height: auto;
    color: #616161;
    font-size: 1em;
    font-weight: 600;
    border-radius: 2em;
}

.dm-box {
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 3em;
    padding: 0 .5em;
    z-index: 990;
    text-align: center;
}

.dm-box .chip {
    margin: 0 auto;
    padding: .5em 1em;
    color: white;
    background-color: rgba(0, 0, 0, .6);
}

.dm-box > a {
    margin: 0 auto;
}

.dm-box > a i {
    margin-bottom: -.75em;
    width: 2em;
    height: 2em;
}
</style>

<template>
    <div v-if="isVisible" class="dm-box">
        <router-link :to="href">
            <div class="chip">
                <i class="icon-qa"></i>最適合我的產品
            </div>
        </router-link>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    export default{
        props:{
            href:{},
            classId:{},
        },
        data(){
            return {
                questionSet:{},
            }
        },
        methods:{
            ...mapActions('global', [
                'fetchQuestionSet'
            ])
        },
        computed:{
            isVisible(){
                return false
                // return [ 5, 6, 7, 8, 9].includes(+this.classId)
            }
        },
    }
</script>
