<style>
    .carousel .indicators .indicator-item {
        background-color: lightgray;
    }

    .carousel .indicators .indicator-item.active {
        background-color: darkgray;
    }
</style>

<template>
    <div :id="id" ref="carousel" class="carousel carousel-slider slider" data-indicators="true">
        <a v-for="url in urls" class="carousel-item" href="#" @mouseup="onMouseUp" @mousedown="onMouseDown" @touchstart="onTouchStart" @touchend="onTouchEnd">
            <img :src="url">
        </a>
    </div>
</template>

<script>
    export default{
        props:{
            id:{},
            urls:{},
            duration:{}
        },
        data(){
            return{
                interval: null
            }
        },
        methods:{
            init(){
                if(this.urls.length == 0) return
                clearInterval(this.interval)
                $(`#${this.id}`).carousel({
                    'fullWidth': true,
                    'indicators': true,
                })
                this.setupInterval()
            },
            onTouchStart(){
                clearInterval(this.interval)
            },
            onTouchEnd(){
                this.setupInterval()
            },
            onMouseUp(){
                this.setupInterval()
            },
            onMouseDown(){
                clearInterval(this.interval)
            },
            setupInterval(){
                if(this.interval) return
                if(this.duration && this.urls.length > 0) {
                    this.interval = setInterval(() => {
                        $(`#${this.id}`).carousel('next');
                    }, this.duration)
                }
            }
        },
        mounted(){
            this.init()
            $(this.$el).on('resize', _ => {
                this.init()
            })
        }
    }
</script>
