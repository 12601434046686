/**
 * Created by dexterliu on 2017/1/3.
 */
import accounting from 'accounting'
import Vue from 'vue'
import VueResource from 'vue-resource'
import mixin from './mixin'
import store from './vuex/store'
import router from './routes'
import App from './App.vue'
import VueAnalytics from 'vue-analytics'
import Multiselect from 'vue-multiselect'
import '@/assets/scss/front.scss'

Vue.component('vue-multi-select', Multiselect)
if (window.GA_ID) {
    Vue.use(VueAnalytics, {
        id: window.GA_ID,
        router,
        commands: {
            trackProductShowClick(product, label) {
                Vue.$ga.event(product, 'click', label)
                Vue.http.post('/api/log-events', {
                    category: product,
                    action: 'click',
                    label
                })
            }
        }
    })
} 
Vue.use(VueResource);

Vue.filter('currency', function (value) {
    return accounting.formatMoney(value, "", 0)
});

Vue.filter('tokenfy', function (value, token) {
    return '/' + token + value;
});

Vue.mixin(mixin)

var EventBus = new Vue()
Object.defineProperties(Vue.prototype, {
    $bus: {
        get: function () {
            return EventBus
        }
    }
}
)

$(_ => {
    new Vue({
        store,
        router,
        render(h) {
            return h(App)
        }
    }).$mount('#app')
})

