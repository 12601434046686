<style scoped>
    select {
        display: block;
    }

    [type="radio"]:checked+label:after {
        border: 2px solid #e57373 !important;
        background-color: #e57373 !important;
    }

    .no-margin-bottom {
        margin-bottom: 0;
    }

    #login-modal .modal-content {
        padding: 6px !important;
    }
</style>
<template>
    <vue-modal id="login-modal" style="max-height: 100%;">
        <div class="row">
            <div v-if="!isLogin" class="col s12">
                <div class="row no-margin-bottom">
                    <div class="col s12">
                        <label>網域</label>
                        <p>
                            <input name="domain" type="radio" id="domain-default" value="" v-model="domain"/>
                            <label for="domain-default">櫻花夥伴</label>
                        </p>
                        <p>
                            <input name="domain" type="radio" id="domain-sakura" value="sakura" v-model="domain"/>
                            <label for="domain-sakura">櫻花同仁</label>
                        </p>
                    </div>
                </div>
                <div class="row no-margin-bottom">
                    <div class="input-field col s12">
                        <input id="email" type="text" class="" v-model="account">
                        <label class="active" for="email">帳號</label>
                    </div>
                </div>
                <div class="row no-margin-bottom">
                    <div class="input-field col s12">
                        <input id="password" type="password" class="" v-model="password">
                        <label class="active" for="password">密碼</label>
                    </div>
                </div>
                <div class="row no-margin-bottom">
                    <div class="input-field col s12">
                        <input id="remember-me" type="checkbox" name="remember-me" v-model="rememberMe">
                        <label class="active" for="remember-me">記住我</label>
                    </div>
                </div>
                <a class="waves-effect waves-light btn red lighten-2" type="submit" @click="onLogin">登入</a>
                <a class="waves-effect waves-light btn red lighten-2" style="margin-right: .5em;" @click="onClose">關閉</a>
            </div>
            <div v-else>
                <p>{{account}}已登入</p>
                <a class="waves-effect waves-light btn red lighten-2" @click="logout">登出</a>
                <a class="waves-effect waves-light btn red lighten-2" style="margin-right: .5em;" @click="onClose">關閉</a>
            </div>
        </div>
    </vue-modal>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'

    export default {
        data(){
            return {
                domain:'',
                account: '',
                password: '',
                rememberMe:false,
                loginFailed: false
            }
        },
        computed:{
            ...mapGetters('global', [
                'isLogin',
                'Account',
                'Password'
            ]),
            loginAccount(){
                return this.account
            }
        },
        methods:{
            ...mapActions('global', [
                'login',
                'logout'
            ]),
            ...mapMutations('global', [
                'setAccount',
                'setPassword',
            ]),
            onLogin(){
                this.login({
                    domain: this.domain,
                    account: this.loginAccount,
                    password: this.password,
                    rememberMe: this.rememberMe
                })
                .then(res => {
                    console.log(res)
                    if(res) {
                        setTimeout(_ => {
                            this.onClose()
                        }, 500)
                    } else {
                        this.loginFailed = true
                        alert('帳號或密碼錯誤')
                    }
                })
            },
            onClose(){
                $('#login-modal').modal('close')
            }
        },
        watch:{
            Account(){
                this.account = this.Account
            },
            Password(){
                this.password = this.Password
            },
            account(){
                this.loginFailed = false
            },
            password(){
                this.loginFailed = false
            }
        },
        mounted(){
            this.account = this.Account
            this.password = this.Password
            this.rememberMe = this.password != ''
            this.$nextTick().then(_ => {
                Materialize.updateTextFields()
            })

        }
    }
</script>