<template>
  <div class="modal" :id="id">
    <div class="modal-content">
      <slot></slot>
    </div>
    <div class="modal-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
    export default {
        props:{
          id:{},
        },
        mounted(){
            this.$nextTick()
            .then(_ => {
                $(this.$el).modal({
                    complete: function(){
                        document.body.style.overflow = 'hidden'
                    }
                });
            })
        }
    }
</script>