/**
 * Created by dexterliu on 2017/1/3.
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
        path:'/:token', component: () => import('./views/IndexView.vue'),
    },
    {
        path:'/:token/video-on-demand', component: () => import('./views/VideoOnDemandView.vue'),
    },
    {
        path:'/:token/dm', component: () => import('./views/DmView.vue'),
    },
    {
        path:'/:token/question-set/:product_class_id', component: () => import('./views/QuestionSetView.vue'),
    },
    {
        path:'/:token/product-classes/parent/:parent_id', component: () => import('./views/ProductClassesIndexView.vue'), meta:{ showCompare: true }
    },
    {
        path:'/:token/products/product-class/:product_class_id', component: () => import('./views/ProductsIndexView.vue'), meta:{ showCompare: true }
    },
    {
        path:'/:token/recommend-products', component:() => import('./views/RecommendProductsIndexView.vue'),
    },
    {
        path:'/:token/products/model/:model+', component: () => import('./views/ProductsShowView.vue'),
    },
    {
        path:'/:token/search-result', component: () => import('./views/ProductSearchResultIndexView.vue'), meta:{ showCompare: true }
    },
    {
        path:'/:token/product-sets', component: () => import('./views/ProductSetsIndexView.vue'),
        beforeEnter(to, from, next){
            if(to.params.token != '6' || !to.query.uofid) {
                router.replace('/')
                return
            }

            next()
        },
    },
    {
        path:'/:token/product-sets/:id', component: () => import('./views/ProductSetsShowView.vue'),
        beforeEnter(to, from, next){
            if(to.params.token != '6' || !to.query.uofid) {
                router.replace('/')
                return
            }
            next()
        },
    },
    {
        path:'*', component:() => import('./views/IndexView.vue')
    }
]

const router = new VueRouter({
    mode:'history',
    routes
})

router.beforeEach((to, from, next) => {
    if(window.GA_ID) {
        Vue.http.post('/api/logs', {
            url: to.fullPath
        })
    }
    document.body.style.overflow = 'hidden'

    next()
})

window.router = router
export default router