<template>
    <layout>
        <router-view></router-view>
    </layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    methods:{
        ...mapActions('global', [
            'fetchChannels'
        ]),
    },
    mounted(){
        this.fetchChannels()
    }
}
</script>

<style>
    .fade-enter-active, .fade-leave-active {
      transition: opacity .25s
    }
    .fade-enter, .fade-leave-active {
      opacity: 0
    }

    .container-scrollable {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 10vw;
        padding-bottom: 8em;
        height: calc(100vh - 56px - 64px - 3em);
        -webkit-overflow-scrolling: touch;
    }
</style>