<template>
    <router-link :to="url">
        <div class="card" :class="{ 'brown lighten-5 max-height-80': !isRoot, 'no-box max-height-100': isRoot }">
            <div class="card-image" :class="{ 'bg-white': !isRoot }">
                <img v-if="info.class_images && info.class_images.length" :src="info.class_images[0]">
                <img v-else src="https://via.placeholder.com/370x370">
            </div>
            <div v-if="info.parent_id != 0" class="card-content">
                <p class="center-align light-blue-text text-darken-4 truncate" v-text="info.name"></p>
            </div>
        </div>
    </router-link>
</template>

<script>
    export default{
        props:{
            info:{}
        },
        computed:{
            isRoot(){
                return this.info.parent_id == 0
            },
            url(){
                if(this.info.id == 'product-sets') return `/${this.token}/product-sets${this.source}`

                if(this.info.children_count != '0' || this.info.children_count != 0)
                    return `/${this.token}/product-classes/parent/${this.info.id}${this.source}`
                else
                    return `/${this.token}/products/product-class/${this.info.id}${this.source}`
            }
        }
    }
</script>

<style scoped>
    .card {
        border-radius: .5em;
    }

    .bg-white {
        background: white !important;
    }

    .no-box {
        box-shadow: none;
    }

    .card-image img {
        aspect-ratio: 1;
    }
</style>
