<template>
    <div class="card brown lighten-5">
        <div class="card-image">
            <router-link :to="href">
                <img v-if="info.images_url.length" :src="info.images_url[0]">
                <img v-else src="https://via.placeholder.com/169x113">
            </router-link>
        </div>
        <div class="card-content">
            <p class="center-align light-blue-text text-darken-4" v-text="info.model"></p>
            <p class="center-align light-blue-text text-darken-4 truncate" v-text="info.name"></p>
            <div style="position: relative;">
                <p v-if="info.size_prices.length" class="center-align red-text truncate">
                    建議售價：{{ info.size_prices[0].price | currency }}
                </p>
                <span v-if="showCompare" style="position: absolute; right: -1.5em; bottom: -1em;">
                    <input type="checkbox" :id="'product' + info.id" :checked="checked" @click.stop="onToggleCheck"/>
                    <label :for="'product' + info.id"></label>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default{
        props:{
            info:{}
        },
        data(){
            return {
            }
        },
        methods:{
            ...mapActions('compare',[
                'add',
                'remove'
            ]),
            onToggleCheck(){
                if(this.checked){
                    this.remove(this.info)
                } else {
                    this.add(this.info)
                }
            }
        },
        computed:{
            ...mapGetters('compare', {
                compareProducts:'products',
                showCompare: 'showCompare'
            }),
            href(){
                return `/${this.token}/products/model/${this.info.model}${this.source}`
            },
            checked(){
                return this.compareProducts.find(p => p.id == this.info.id) ? true : false
            }
        },
    }
</script>

<style scoped>
    .vertical-scrollable {
        overflow-x: auto; white-space: nowrap;
    }

    [type="checkbox"]+label:before{
        border: 1px solid #e57373;
    }

    [type="checkbox"]:checked+label:before {
        border: 0;
        border-bottom: 2px solid #e57373;
        border-right: 2px solid #e57373;
    }

    .card {
        border-radius: .5em;
    }

    .card-image {
        background: white;
    }
</style>