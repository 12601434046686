<template>
    <ul class="collapsible" data-collapsible="accordion">
        <li>
            <div class="collapsible-header">
                <span class="right">
                    <i class="material-icons arrow_drop_down">arrow_drop_down</i>
                    <i class="material-icons arrow_drop_up">arrow_drop_up</i>
                </span>
                <span class="right">
                    比較商品({{compareProducts.length}})
                </span>
            </div>
            <div class="collapsible-body">
                <div v-if="compareProducts.length" class="container-fluid">
                    <div v-if="isLandscape">
                        <div class="row no-margin-bottom">
                            <div v-for="product in compareProducts" class="col s6">
                                <a class="btn-floating red btn-remove right" @click.prevent.stop="remove(product)">
                                    <i class="material-icons">remove</i>
                                </a>
                            </div>
                        </div>
                        <div class="row no-margin-bottom">
                            <div v-for="product in compareProducts" class="col s6">
                                <h4 v-text="product.model" class="center-align"></h4>
                            </div>
                        </div>
                        <div class="row no-margin-bottom">
                            <div v-for="product in compareProducts" class="col s6">
                                <h4 v-text="product.name" class="center-align"></h4>
                            </div>
                        </div>
                        <div class="row no-margin-bottom">
                            <div v-for="product in compareProducts" class="col s6">
                                <div class="row">
                                    <div class="col s12 offset-l2">
                                        <img :src="product.images_url[0]" class="responsive-img">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-margin-bottom">
                            <div v-for="product in compareProducts" class="col s6">
                                <div class="row" v-for="sp in product.size_prices">
                                    <div class="col s12 m12 l6">
                                        <h4 v-text="sp.size"></h4>
                                    </div>
                                    <div class="col s12 m12 l6">
                                        <h4 class="price">建議售價：<strong>{{sp.price | currency}}</strong></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-margin-bottom">
                            <div v-for="product in compareProducts" class="col s6">
                                <h5 style="color:black;" v-text="product.price_memo"></h5>
                            </div>
                        </div>
                        <div class="row no-margin-bottom">
                            <div v-for="product in compareProducts" class="col s6">
                                <div v-for="f in product.features" class="col s12 m12 l6">
                                    <div class="row">
                                        <div class="col s3">
                                            <img :src="f.images_url[0]" class="responsive-img">
                                        </div>
                                        <div class="col s9">
                                            <div class="block-text" v-text="f.name"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-margin-bottom">
                            <div v-for="product in compareProducts" class="col s6">
                                <p class="description" v-html="product.description"></p>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col s12">
                            請橫放裝置
                        </div>
                    </div>
                </div>
                <div v-else class="valign-wrapper" style="min-height: 40vh;">
                    <h5 class="valign center" style="width:100%;">未加入任何商品</h5>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default{
        data(){
            console.log(screen.width, screen.height)
            return {
                isLandscape: window.innerWidth / window.innerHeight > 1,
            }
        },
        methods:{
            ...mapActions('compare', {
                removeCompareProduct:'remove'
            }),
            remove(product){
                this.removeCompareProduct(product)
            },
        },
        computed:{
            ...mapGetters('compare', {
                compareProducts:'products'
            }),
        },
        watch:{
            compareProducts(){
                if(!this.compareProducts.length) {
                    $('.collapsible-header.active').trigger('click.collapse')
                }
            },
        },
        mounted(){
            $('.collapsible').collapsible()

            let vm = this
            window.onresize = function(e){
                vm.isLandscape = window.innerWidth / window.innerHeight > 1
            }
        }
    }
</script>

<style>
    .description p {
        padding: 0;
    }

    .description table {
        table-layout: fixed;
        width: 100% !important;
    }

    .description table td{
        padding: 0;
        margin: 0;
        word-wrap: break-word;
        width: inherit !important;
        height: inherit !important;
    }
</style>

<style scoped>
    .row.no-margin-bottom {
        margin-bottom: 0;
    }

    .price {
        color: #d32f2f;
    }

    .collapsible {
        border-top: 0;
        z-index: 998;
        margin: 0;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: none;
    }

    .active .collapsible-body {
        background: white;
        max-height: calc(100vh - 3em);
        overflow-y: auto;
    }

    .collapsible-header i.arrow_drop_down{
        display: none;
    }

    .collapsible-header i.arrow_drop_up{
        display: inline-block;
    }

    .collapsible-header.active i.arrow_drop_down{
        display: inline-block;
    }

    .collapsible-header.active i.arrow_drop_up{
        display: none;
    }
</style>