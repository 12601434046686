const productSets = {
    state:{
       items:[
           {
               id:1,
               title: '旗艦系列',
               image: '/img/product-sets/旗艦系列.jpg'
           },
           {
               id:2,
               title: '創新系列',
               image: '/img/product-sets/創新系列.jpg'
           },
           {
               id:3,
               title: '精緻系列',
               image: '/img/product-sets/精緻系列.jpg'
           },
           {
               id:4,
               title: '時尚系列',
               image: '/img/product-sets/時尚系列.jpg'
           },
           {
               id:5,
               title: '易清系列',
               image: '/img/product-sets/易清系列.jpg'
           },
       ]
    },
    getters:{
        items(state){
            return state.items
        }
    },
    actions:{

    }
}

export default productSets