/**
 * Created by dexterliu on 2017/1/4.
 */
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import global from './modules/global'
import compare from './modules/compare'
import productSets from './modules/productSets'

const store = new Vuex.Store({
    modules:{
        global:{
            namespaced: true,
            ...global
        },
        compare:{
            namespaced: true,
            ...compare
        },
        productSets:{
            namespaced: true,
            ...productSets
        }
    }
})

export default store