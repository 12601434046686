<template>
    <select>
        <slot></slot>
    </select>
</template>

<script>
    export default {
        props: ['value'],
        watch: {
            value(value){
                this.relaod(value);
            }
        },
        methods:{
          relaod(value) {
              let select = $(this.$el);
              select.val(value || this.value);
              select.material_select('destroy');
              select.material_select();
          }
        },
        mounted() {
            let select = $(this.$el);
            select
                .val(this.value)
                .on('change', e => {
                    this.$emit('input', e.target.value);
                });

            select.material_select();
        },
        updated() {
            this.relaod();
        },
        destroyed() {
            $(this.$el).material_select('destroy');
        }
    }
</script>