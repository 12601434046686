/**
 * Created by dexterliu on 2017/1/4.
 */
import Vue from 'vue'

const global = {
    state: {
        channels: [],
        domain: localStorage.getItem('domain') || '',
        account: localStorage.getItem('account') || '',
        password: localStorage.getItem('password') || '',
        isLogin: localStorage.getItem('isLogin') === 'true' || false
    },
    getters: {
        channels(state) {
            return state.channels
        },
        isLogin(state){
            return state.isLogin
        },
        Domain(state){
            return state.domain
        },
        Account(state){
            return state.account
        },
        Password(state){
            return state.password
        }
    },
    mutations: {
        fetchChannels(state, channels){
            state.channels = channels
        },
        setLogin(state, value) {
            state.isLogin = value
            localStorage.setItem('isLogin', value);
        },
        setDomain(state, value) {
            state.domain = value
            localStorage.setItem('domain', value);
        },
        setAccount(state, value) {
            state.account = value
            localStorage.setItem('account', value);
        },
        setPassword(state, value) {
            state.password = value
            localStorage.setItem('password', value);
        }
    },
    actions: {
        fetchChannels (context) {
            Vue.http.get( window.API_URL + '/api/channels')
            .then(res => {
                context.commit('fetchChannels', res.data)
            })
        },
        fetchQuestionSet(context, { product_class_id }){
            console.log(product_class_id)
            return Vue.http.get(`${window.API_URL}/api/question-sets/${product_class_id}`)
        },
        login(context, payload){
            let apiURL = 'https://partner.sakura.com.tw/sakura/CDS/Redirect/WebService.asmx/Login'
            let postData = {
                account: payload.account,
                password: payload.password
            }

            if(payload.domain === 'sakura') {
                apiURL = 'https://partner.sakura.com.tw/sakura/CDS/Redirect/WebService.asmx/DomainLogin'
                postData.domainName = payload.domain
            }

            return Vue.http.post(apiURL, postData, {
                emulateJSON: true
            })
            .then(res => {
                context.commit('setDomain', payload.domain)
                context.commit('setAccount', payload.account)
                if(payload.rememberMe) {
                    context.commit('setPassword', payload.password)
                } else {
                    context.commit('setPassword', '')
                }

                let parser = new DOMParser();
                let xmlDoc = parser.parseFromString(res.data,"text/xml");
                let value = xmlDoc.getElementsByTagName('string')[0].childNodes[0].nodeValue.toLowerCase() == 'true'
                context.commit('setLogin', value)
                return value
            })
        },
        logout(context){
            context.commit('setDomain', '')
            context.commit('setAccount', '')
            context.commit('setPassword', '')
            context.commit('setLogin', false)
        }
    }
}

export default global