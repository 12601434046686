<template>
    <nav style="box-shadow: none;">
        <div class="nav-wrapper white lighten-5" style="overflow-x: auto; overflow-y: hidden; width: 100vw; white-space: nowrap;">
            <div class="col s12">
                <template v-for="(b, i) in breadcrumbs">
                    <a v-if="b.external" :href="b.href" class="breadcrumb black-text" v-html="b.name"></a>
                    <a v-else-if="b.onClick" class="breadcrumb black-text" v-html="b.name" @click.stop.prev="b.onClick"></a>
                    <router-link v-else :to="b.href" class="breadcrumb black-text" v-html="b.name"></router-link>
                </template>
            </div>
        </div>
    </nav>
</template>

<script>
    export default{
        props:{
            breadcrumbs:{
                default:[]
            }
        },
    }
</script>

<style scoped>
    .breadcrumb:before {
        color: black;
    }
</style>